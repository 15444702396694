// @flow
import React, {Component} from 'react';
import Routes from './routes/Routes';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import IdleTimer, { useIdleTimer } from 'react-idle-timer'

// Themes
import './assets/scss/Saas.scss';

// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern import Modern.scss
// import './assets/scss/Modern.scss';
// For modern dark import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Modern.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Modern.scss
// import './assets/scss/Creative-Dark.scss';

// Tandem Custom SCSS
import './assets/scss/Tandem.scss';
import './assets/scss/TandemDynamic.scss';
import SweetAlert from "react-bootstrap-sweetalert";

type AppProps = {};

let language = window.localStorage.i18nextLng ? window.localStorage.i18nextLng : 'es' ;

const translations = {
    es: {
        idle: {
            title: "¿Todavía estás ahí?",
            message: "Por seguridad, tu sesión se ha cerrado. Disculpa las molestias.",
            button: "Volver a entrar"
        }
    },
    ca: {
        idle: {
            title: "Encara hi ets?",
            message: "Per seguretat, la sessió s'ha tancat. Disculpa les molèsties.",
            button: "Torna a entrar"
        }
    },
    en: {
        idle: {
            title: "Are you still there?",
            message: "For security reasons, your session has been closed. We apologize for the inconvenience.",
            button: "Log in again"
        }
    },
    eu: {
        idle: {
            title: "Oraindik al zaude?",
            message: "Segurtasun arrazoiengatik, zure saioa itxi egin da. Barkatu eragozpenak.",
            button: "Sartu berriro"
        }
    }
}

/**
 * Main app component
 */
class App extends Component<AppProps> {

    // https://github.com/SupremeTechnopriest/react-idle-timer/tree/v4
    // https://www.npmjs.com/package/react-idle-timer/v/4.6.4

    constructor(props) {
        super(props)
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.handleSessionEnd = this.handleSessionEnd.bind(this)
        this.state = {
            isIdle: false
        };
    }

    handleOnAction (event) {
        console.log('user did something', event)
    }

    handleOnActive (event) {
        console.log('user is active', event)
        console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    handleOnIdle (event) {
        this.setState({
            isIdle: true
        }, () => {
            console.log(this.state.isIdle, 'IDLE');
        });
    }

    handleSessionEnd() {
        this.setState({
            isIdle: false
        }, () => {
            console.log(this.state.isIdle, 'IDLE');
            window.location.replace("/account/logout");
        });
    }

    componentDidMount() {
        CookieConsent.run(cookiesOptions).then(r => console.log('COOKIES'));
    }

    render() {
        return <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            timeout={1800000} /* 30 minutes */
            onIdle={this.handleOnIdle}
            // crossTab={true}
            // onAction={this.handleOnAction}
            // events={[]}
            // onActive={this.handleOnActive}
            debounce={250}
            stopOnIdle={true}
            startManually={false}
        >
            <Routes></Routes>
            {this.state.isIdle &&
            <SweetAlert title={translations[language]["idle"]["title"]}
                        onClose={this.handleSessionEnd}
                        onConfirm={this.handleSessionEnd}
                        btnSize="sm"
                        confirmBtnBsStyle={'success'}
                        confirmBtnText={translations[language]["idle"]["button"]}>
                {translations[language]["idle"]["message"]}
            </SweetAlert>}
        </IdleTimer>;
    }
}

export default App;

const cookiesOptions= {
    revision: 4,
    manageScriptTags: true,
    cookie: {
        name: 'cc_cookie',
        domain: window.location.hostname,
        path: '/',
        sameSite: "Lax",
        expiresAfterDays: 365
    },
    guiOptions: {
        consentModal: {
            layout: 'cloud inline',
            position: 'bottom center',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    onFirstConsent: ({cookie}) => {

    },

    onConsent: ({cookie}) => {

    },

    onChange: ({changedCategories, changedServices}) => {

    },

    onModalReady: ({modalName}) => {

    },

    onModalShow: ({modalName}) => {

    },

    onModalHide: ({modalName}) => {

    },

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^_ga/,   // regex: match all cookies starting with '_ga'
                    },
                    {
                        name: /^_hj/,   // regex: match all cookies starting with '_hj'
                    },
                    {
                        name: '_gid',   // string: exact cookie name
                    }
                ]
            },
            // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services
            services: {
                ga: {
                    label: 'Google Analytics',
                    onAccept: () => {},
                    onReject: () => {}
                },
                hotjar: {
                    label: 'Hotjar',
                    onAccept: () => {

                    },
                    onReject: () => {}
                },
            }
        },
    },
    language: {
        default: language,
        translations: {
            'es': {
                consentModal: {
                    title: "Utilizamos cookies",
                    description: "Este sitio utiliza cookies para mejorar la experiencia de usuario y analizar las visitas recibidas",
                    acceptAllBtn: "Aceptar todo",
                    acceptNecessaryBtn: "Rechazar todo",
                    showPreferencesBtn: "Gestionar preferencias"
                },
                preferencesModal: {
                    title: "Preferencias de cookies",
                    acceptAllBtn: "Aceptar todo",
                    acceptNecessaryBtn: "Rechazar todo",
                    savePreferencesBtn: "Guardar preferencias",
                    closeIconLabel: "Cerrar",
                    sections: [
                        {
                            title: "Uso de cookies",
                            description: "Utilizamos cookies para garantizar las funcionalidades básicas del sitio web y mejorar su experiencia en línea..."
                        },
                        {
                            title: "Cookies estrictamente necesarias",
                            description: "Estas cookies son esenciales para el correcto funcionamiento de mi sitio web. Sin estas cookies, el sitio web no funcionaría correctamente",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Cookies de rendimiento y análisis",
                            description: "Estas cookies permiten que el sitio web recuerde las opciones que ha elegido en el pasado",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Nombre",
                                    domain: "Servicio",
                                    description: "Descrición",
                                    expiration: "Caducidad"
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "Google Analytics",
                                        description: "Cookie establecida por Google Analytics",
                                        expiration: "Caduca después de 12 dies"
                                    },
                                    {
                                        name: "_gid",
                                        domain: "Google Analytics",
                                        description: "Cookie establecida por Google Analytics",
                                        expiration: "Con la sesión"
                                    },
                                    {
                                        name: "_hjid",
                                        domain: "Hotjar",
                                        description: "Cookie establecida por Hotjar",
                                        expiration: "3 meses"
                                    },
                                    {
                                        name: "_hjSessionUser",
                                        domain: "Hotjar",
                                        description: "Cookie establecida por Hotjar",
                                        expiration: "3 meses"
                                    }
                                ]
                            }
                        },
                        {
                            title: "Más información",
                            description: "Para cualquier consulta en relación con nuestra política de cookies y sus opciones, por favor <a class=\"cc-link\" href=\"https://tandemgo.coop/contact\">contacta con nosotros</a>."
                        }
                    ]
                }
            },
            'ca': {
                consentModal: {
                    title: "Utilitzem cookies",
                    description: "Aquest lloc web utilitza galetes per millorar l'experiència d'usuari i analitzar les visites rebudes",
                    acceptAllBtn: "Acceptar tot",
                    acceptNecessaryBtn: "Rebutjar tot",
                    showPreferencesBtn: "Gestionar preferències"
                },
                preferencesModal: {
                    title: "Preferències de cookies",
                    acceptAllBtn: "Acceptar tot",
                    acceptNecessaryBtn: "Rebutjar tot",
                    savePreferencesBtn: "Desar preferències",
                    closeIconLabel: "Tancar",
                    sections: [
                        {
                            title: "Ús de cookies",
                            description: "Utilitzem cookies per garantir les funcionalitats bàsiques del lloc web i millorar la seva experiència en línia..."
                        },
                        {
                            title: "Cookies estrictament necessàries",
                            description: "Aquestes cookies són essencials per al funcionament correcte del meu lloc web. Sense aquestes cookies, el lloc web no funcionaria correctament",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Cookies de rendiment i anàlisi",
                            description: "Aquestes cookies permeten que el lloc web recordi les opcions que heu triat en el passat",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Nom",
                                    domain: "Servei",
                                    description: "Descripció",
                                    expiration: "Caducitat"
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "Google Analytics",
                                        description: "Cookie establerta per Google Analytics",
                                        expiration: "Caduca després de 12 dies"
                                    },
                                    {
                                        name: "_gid",
                                        domain: "Google Analytics",
                                        description: "Cookie establerta per Google Analytics",
                                        expiration: "Amb la sessió"
                                    },
                                    {
                                        name: "_hjid",
                                        domain: "Hotjar",
                                        description: "Cookie establerta per Hotjar",
                                        expiration: "3 mesos"
                                    },
                                    {
                                        name: "_hjSessionUser",
                                        domain: "Hotjar",
                                        description: "Cookie establerta per Hotjar",
                                        expiration: "3 mesos"
                                    }
                                ]
                            }
                        },
                        {
                            title: "Més informació",
                            description: "Per a qualsevol consulta en relació amb la nostra política de cookies i les seves opcions, si us plau <a class=\"cc-link\" href=\"https://tandemgo.coop/contact\">contacta amb nosaltres</a>"
                        }
                    ]
                }
            },
            'en': {
                consentModal: {
                    title: "Consent Modal Title",
                    description: "Consent Modal Description",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences"
                },
                preferencesModal: {
                    title: "Cookie preferences",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close",
                    sections: [
                        {
                            title: "Cookie usage",
                            description: "We use cookies to ensure the basic functionalities of the website and to enhance your online experience ..."
                        },
                        {
                            title: "Strictly necessary cookies",
                            description: "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Performance and Analytics cookies",
                            description: "These cookies allow the website to remember the choices you have made in the past",
                            linkedCategory: "analytics",
                            cookieTable: {
                                headers: {
                                    name: "Name",
                                    domain: "Service",
                                    description: "Description",
                                    expiration: "Expiration"
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "Google Analytics",
                                        description: "Cookie set by Google Analytics",
                                        expiration: "Expires after 12 days"
                                    },
                                    {
                                        name: "_gid",
                                        domain: "Google Analytics",
                                        description: "Cookie set by Google Analytics",
                                        expiration: "Session"
                                    },
                                    {
                                        name: "_hjid",
                                        domain: "Hotjar",
                                        description: "Cookie set by Hotjar",
                                        expiration: "3 months"
                                    },
                                    {
                                        name: "_hjSessionUser",
                                        domain: "Hotjar",
                                        description: "Cookie set by Hotjar",
                                        expiration: "3 months"
                                    }
                                ]
                            }
                        },
                        {
                            title: "More information",
                            description: "For any queries in relation to our policy on cookies and your choices, please <a class=\"cc-link\" href=\"https://tandemgo.coop/contact\">contact us</a>."
                        }
                    ]
                }
            }
        }
    }
}
