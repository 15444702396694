// @flow
import {
    COMMON_REQUEST,
    COMMON_SUCCESS,
    COMMON_FAILED,
    COUNTRY_REQUEST,
    COUNTRY_SUCCESS,
    COUNTRY_FAILED,
    PROVINCE_REQUEST,
    PROVINCE_SUCCESS,
    PROVINCE_FAILED,
    CITY_REQUEST,
    CITY_SUCCESS,
    CITY_FAILED,
    COMPANY_REQUEST,
    COMPANY_SUCCESS,
    COMPANY_FAILED,
    AUTONOMY_REQUEST,
    AUTONOMY_SUCCESS,
    AUTONOMY_FAILED,
    FAQ_CATEGORY_REQUEST,
    FAQ_CATEGORY_SUCCESS,
    FAQ_CATEGORY_FAILED,
    MODALITY_CATEGORY_REQUEST,
    MODALITY_CATEGORY_SUCCESS,
    MODALITY_CATEGORY_FAILED,
    ADMIN_HISTORIC_REQUEST,
    ADMIN_HISTORIC_SUCCESS,
    ADMIN_HISTORIC_FAILED,
    CARD_GENERATE,
    CARD_GENERATE_SUCCESS,
    CARD_GENERATE_FAILED,
    CARD_PIN_REQUEST,
    CARD_PIN_REQUEST_SUCCESS,
    CARD_PIN_REQUEST_FAILED,
    CARD_TOGGLE,
    CARD_TOGGLE_SUCCESS,
    CARD_TOGGLE_FAILED,
    CARD_TRANSACTIONS,
    CARD_TRANSACTIONS_SUCCESS,
    CARD_TRANSACTIONS_FAILED,
    CARD_RECHARGE,
    CARD_RECHARGE_SUCCESS,
    CARD_RECHARGE_FAILED,
    CANCEL_SELECTION_REQUEST,
    CANCEL_SELECTION_SUCCESS,
    CANCEL_SELECTION_FAILED,
    CANCEL_INSURANCE_REQUEST,
    CANCEL_INSURANCE_SUCCESS,
    CANCEL_INSURANCE_FAILED,
    CARD_BALANCE,
    CARD_BALANCE_SUCCESS,
    CARD_BALANCE_FAILED,
    CARD_RECHARGE_NOTIFICATION,
    CARD_RECHARGE_NOTIFICATION_SUCCESS,
    CARD_RECHARGE_NOTIFICATION_FAILED,
    CARD_BALANCE_RETURN,
    CARD_BALANCE_RETURN_SUCCESS, CARD_BALANCE_RETURN_FAILED
} from './constants';

const initialState = {
    loading: false,
    error: '',
    cardLoading: false,
    cardError: '',
    cardPinLoading: false,
    cardPinError: '',
    cardTransactionsLoading: false,
    cardTransactionsError: '',
    cardToggleLoading: false,
    cardToggleError: '',
    cardRechargeLoading: false,
    cardRechargeError: '',
    cardBalanceLoading: false,
    cardBalanceError: '',
    cardBalanceReturnLoading: false,
    cardBalanceReturnError: '',
    countries: [],
    provinces: [],
    cities: [],
    companies: [],
    autonomies: [],
    faqCategories: [],
    modalityCategories: [],
    records: [],
    feedback: false,
    card: [],
    cancelSelectionResult: '',
    cancelSelectionLoading: false,
    cancelSelectionError: false,
    cancelInsuranceResult: '',
    cancelInsuranceLoading: false,
    cancelInsuranceError: false,
    cardRechargeNotificationLoading: false,
    cardRechargeNotificationError: '',
    cardRechargeNotification: '',
};

export default function CommonReducer(state= initialState, action) {
    switch (action.type) {
        case COMMON_REQUEST:
            return { ...state, loading: true, error: null };
        case COMMON_SUCCESS:
            return { ...state, feedback: true, loading: false };
        case COMMON_FAILED:
            return { ...state, error: action.error, loading: false };
        case COUNTRY_REQUEST:
            return { ...state, loading: true, error: null };
        case COUNTRY_SUCCESS:
            return { ...state, countries: action.countries, loading: false };
        case COUNTRY_FAILED:
            return { ...state, error: action.error, loading: false };
        case PROVINCE_REQUEST:
            return { ...state, loading: true, error: null };
        case PROVINCE_SUCCESS:
            return { ...state, provinces: action.provinces, loading: false };
        case PROVINCE_FAILED:
            return { ...state, error: action.error, loading: false };
        case CITY_REQUEST:
            return { ...state, loading: true, error: null };
        case CITY_SUCCESS:
            return { ...state, cities: action.cities, loading: false };
        case CITY_FAILED:
            return { ...state, error: action.error, loading: false };
        case COMPANY_REQUEST:
            return { ...state, loading: true, error: null };
        case COMPANY_SUCCESS:
            return { ...state, companies: action.companies, loading: false };
        case COMPANY_FAILED:
            return { ...state, error: action.error, loading: false };
        case AUTONOMY_REQUEST:
            return { ...state, loading: true, error: null };
        case AUTONOMY_SUCCESS:
            return { ...state, autonomies: action.autonomies, loading: false };
        case AUTONOMY_FAILED:
            return { ...state, error: action.error, loading: false };
        case FAQ_CATEGORY_REQUEST:
            return { ...state, loading: true, error: null };
        case FAQ_CATEGORY_SUCCESS:
            return { ...state, faqCategories: action.faqCategories, loading: false };
        case FAQ_CATEGORY_FAILED:
            return { ...state, error: action.error, loading: false };
        case MODALITY_CATEGORY_REQUEST:
            return { ...state, loading: true, error: null };
        case MODALITY_CATEGORY_SUCCESS:
            return { ...state, modalityCategories: action.modalityCategories, loading: false };
        case MODALITY_CATEGORY_FAILED:
            return { ...state, error: action.error, loading: false };
        case ADMIN_HISTORIC_REQUEST:
            return { ...state, loading: true, error: null };
        case ADMIN_HISTORIC_SUCCESS:
            return { ...state, records: action.records, loading: false };
        case ADMIN_HISTORIC_FAILED:
            return { ...state, error: action.error, loading: false };

        case CARD_GENERATE:
            return { ...state, cardLoading: true, cardError: null };
        case CARD_GENERATE_SUCCESS:
            return { ...state, card: action.card, cardLoading: false };
        case CARD_GENERATE_FAILED:
            return { ...state, cardError: action.error, cardLoading: false };
        case CARD_PIN_REQUEST:
            return { ...state, cardPinLoading: true, cardPinError: null };
        case CARD_PIN_REQUEST_SUCCESS:
            return { ...state, cardPin: action.cardPin, cardPinLoading: false };
        case CARD_PIN_REQUEST_FAILED:
            return { ...state, cardPinError: action.error, cardPinLoading: false };
        case CARD_TOGGLE:
            return { ...state, cardToggleLoading: true, cardToggleError: null };
        case CARD_TOGGLE_SUCCESS:
            return { ...state, cardToggle: action.cardToggle, cardToggleLoading: false };
        case CARD_TOGGLE_FAILED:
            return { ...state, cardToggleError: action.error, cardToggleLoading: false };
        case CARD_TRANSACTIONS:
            return { ...state, cardTransactionsLoading: true, cardTransactionsError: null };
        case CARD_TRANSACTIONS_SUCCESS:
            return { ...state, cardTransactions: action.cardTransactions, cardTransactionsLoading: false };
        case CARD_TRANSACTIONS_FAILED:
            return { ...state, cardTransactionsError: action.error, cardTransactionsLoading: false };
        case CARD_RECHARGE:
            return { ...state, cardRecharge: false, cardRechargeLoading: true, cardRechargeError: null };
        case CARD_RECHARGE_SUCCESS:
            return { ...state, cardRecharge: action.cardRecharge, cardRechargeLoading: false };
        case CARD_RECHARGE_FAILED:
            return { ...state, cardRecharge: true, cardRechargeError: action.error, cardRechargeLoading: false };
        case CARD_RECHARGE_NOTIFICATION:
            return { ...state, cardRechargeNotificationLoading: true, cardRechargeNotificationError: null };
        case CARD_RECHARGE_NOTIFICATION_SUCCESS:
            return { ...state, cardRechargeNotification: action.cardRechargeNotification, cardRechargeNotificationLoading: false };
        case CARD_RECHARGE_NOTIFICATION_FAILED:
            return { ...state, cardRechargeNotificationError: action.error, cardRechargeNotificationLoading: false };
        case CARD_BALANCE:
            return { ...state, cardBalanceLoading: true, cardBalanceError: null };
        case CARD_BALANCE_SUCCESS:
            return { ...state, cardBalance: action.cardBalance, cardBalanceLoading: false };
        case CARD_BALANCE_FAILED:
            return { ...state, cardBalance: null, cardBalanceError: action.error, cardBalanceLoading: false };
        case CARD_BALANCE_RETURN:
            return { ...state, cardBalanceReturnLoading: true, cardBalanceReturnError: null };
        case CARD_BALANCE_RETURN_SUCCESS:
            return { ...state, cardBalanceReturn: action.cardBalanceReturn, cardBalanceReturnLoading: false };
        case CARD_BALANCE_RETURN_FAILED:
            return { ...state, cardBalanceReturn: null, cardBalanceReturnError: action.error, cardBalanceReturnLoading: false };

        case CANCEL_SELECTION_REQUEST:
            return { ...state, cancelSelectionLoading: true, cancelSelectionError: null };
        case CANCEL_SELECTION_SUCCESS:
            return { ...state, cancelSelectionResult: action.result, cancelSelectionLoading: false };
        case CANCEL_SELECTION_FAILED:
            return { ...state, cancelSelectionError: action.error, cancelSelectionLoading: false };

        case CANCEL_INSURANCE_REQUEST:
            return { ...state, cancelInsuranceLoading: true, cancelInsuranceError: null };
        case CANCEL_INSURANCE_SUCCESS:
            return { ...state, cancelInsuranceResult: action.result, cancelInsuranceLoading: false };
        case CANCEL_INSURANCE_FAILED:
            return { ...state, cancelInsuranceError: action.error, cancelInsuranceLoading: false };

        default:
            return { ...state };
    }
}