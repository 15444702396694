// @flow
import {
    COMMON_REQUEST,
    COUNTRY_REQUEST,
    PROVINCE_REQUEST,
    CITY_REQUEST,
    COMPANY_REQUEST,
    AUTONOMY_REQUEST,
    FAQ_CATEGORY_REQUEST, MODALITY_CATEGORY_REQUEST,
    ADMIN_HISTORIC_REQUEST,
    CARD_GENERATE, CARD_TOGGLE, CARD_PIN_REQUEST, CARD_TRANSACTIONS, CARD_RECHARGE,
    CANCEL_SELECTION_REQUEST, CARD_BALANCE, CANCEL_INSURANCE_REQUEST, CARD_RECHARGE_NOTIFICATION, CARD_BALANCE_RETURN
} from './constants';

type CommonAction = { type: string, payload: {} | string };

export const commonRequest = (path, params): CommonAction => ({
    type: COMMON_REQUEST,
    payload: {path, params},
});

export const countryRequest = (): CommonAction => ({
    type: COUNTRY_REQUEST,
    payload: {},
});

export const provinceRequest = (): CommonAction => ({
    type: PROVINCE_REQUEST,
    payload: {},
});

export const cityRequest = (province): CommonAction => ({
    type: CITY_REQUEST,
    payload: {province},
});

export const cardGenerate = (user): CommonAction => ({
    type: CARD_GENERATE,
    payload: {user},
});

export const cardToggle = (action): CommonAction => ({
    type: CARD_TOGGLE,
    payload: {action},
});

export const cardPinRequest = (): CommonAction => ({
    type: CARD_PIN_REQUEST,
    payload: {},
});

export const cardTransactions = (type): CommonAction => ({
    type: CARD_TRANSACTIONS,
    payload: {type},
});

export const cardRecharge = (year, month, param): CommonAction => ({
    type: CARD_RECHARGE,
    payload: {year, month, param},
});

export const cardRechargeNotification = (id): CommonAction => ({
    type: CARD_RECHARGE_NOTIFICATION,
    payload: {id},
});

export const cardBalance = (year, month, isRecharge): CommonAction => ({
    type: CARD_BALANCE,
    payload: {year, month, isRecharge},
});

export const cardBalanceReturn = (mp, callback): CommonAction => ({
    type: CARD_BALANCE_RETURN,
    payload: {mp, callback},
});

export const companyRequest = (id): CommonAction => ({
    type: COMPANY_REQUEST,
    payload: {id},
});

export const autonomyRequest = (): CommonAction => ({
    type: AUTONOMY_REQUEST,
    payload: {},
});

export const faqCategoryRequest = (language): CommonAction => ({
    type: FAQ_CATEGORY_REQUEST,
    payload: {language},
});

export const modalityCategoryRequest = (): CommonAction => ({
    type: MODALITY_CATEGORY_REQUEST,
    payload: {},
});

export const adminHistoricRequest = (values): CommonAction => ({
    type: ADMIN_HISTORIC_REQUEST,
    payload: {values},
});

export const cancelSelectionRequest = (values): CommonAction => ({
    type: CANCEL_SELECTION_REQUEST,
    payload: {values},
});

export const cancelInsuranceRequest = (values): CommonAction => ({
    type: CANCEL_INSURANCE_REQUEST,
    payload: {values},
});
