export const UPDATE_COMPANY_CONTRACT = 'UPDATE_COMPANY_CONTRACT';
export const UPDATE_COMPANY_CONTRACT_SUCCESS = 'UPDATE_COMPANY_CONTRACT_SUCCESS';
export const UPDATE_COMPANY_CONTRACT_FAILED = 'UPDATE_COMPANY_CONTRACT_FAILED';
export const REQUEST_COMPANY_SIGNATURE = 'REQUEST_COMPANY_SIGNATURE';
export const REQUEST_COMPANY_SIGNATURE_SUCCESS = 'REQUEST_COMPANY_SIGNATURE_SUCCESS';
export const REQUEST_COMPANY_SIGNATURE_FAILED = 'REQUEST_COMPANY_SIGNATURE_FAILED';
export const RESET_COMPANY_CONTRACT = 'RESET_COMPANY_CONTRACT';
export const COMPANY_SERVICES = 'COMPANY_SERVICES_REQUEST';
export const COMPANY_SERVICES_SUCCESS = 'COMPANY_SERVICES_SUCCESS';
export const COMPANY_SERVICES_FAILED = 'COMPANY_SERVICES_FAILED';
export const COMPANY_COSTS = 'COMPANY_COSTS';
export const COMPANY_COSTS_SUCCESS = 'COMPANY_COSTS_SUCCESS';
export const COMPANY_COSTS_FAILED = 'COMPANY_COSTS_FAILED';


